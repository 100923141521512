<template>
  <div>
    <header>
      <div class="Header_Menu">
        <div
          id="Porta_Btn_OpenMenu"
          class="Porta_Btn_OpenMenu d-flex align-items-center justify-content-center"
        >
          <button
            type="button"
            @click="OpenMenu()"
            id="Btn_OpenMenu"
            class="Btn_OpenMenu"
          >
            <img src="./svg/Menu_Open.svg" class="Icone_Svg_Menu" alt="Menu" />
          </button>

          <button
            type="button"
            @click="CloseMenu()"
            id="Btn_CloseMenu"
            class="Btn_CloseMenu Hide_Btn_CloseMenu"
          >
            <img
              src="./svg/Menu_Close.svg"
              class="Icone_Svg_Close"
              alt="CloseMenu"
            />
          </button>
        </div>

        <div class="Porta_Btn_OpenMenu_Mobile">
          <button type="button" class="Btn_OpenMenu_Mobile">
            <img src="./svg/Menu_Open.svg" class="Icone_Svg_Menu" alt="Menu" />
          </button>

          <button type="button" class="Btn_CloseMenu_Mobile Hide_Btn_CloseMenu">
            <img
              src="./svg/Menu_Close.svg"
              class="Icone_Svg_Close"
              alt="CloseMenu"
            />
          </button>
        </div>

        <div class="Porta_Menu" id="Porta_Menu">
          <div class="container-fluid">
            <!--Logo-->
            <div
              class="PortaLogo AlturaMenu d-flex align-items-center justify-content-start"
            >
              <a class="VoltarNavegacao d-none d-sm-block" href="/">
                <img
                  src="./svg/LensExpert___Logo.svg"
                  class="Logo_HeaderMenu Logo_Color"
                  alt="LensXpert"
                />

                <img
                  src="./svg/LensExpert___Logo.svg"
                  class="Logo_HeaderMenu Logo_White Hide_Logo_White"
                  alt="LensXpert"
                />
              </a>

              <a class="btn-scroll d-block d-sm-none" href="javascript:void(0)">
                <img
                  src="./svg/LensXpert___LogoMobile.svg"
                  class="Logo_HeaderMenu"
                  alt="LensXpert"
                />
              </a>
            </div>
            <!--Logo-->

            <!--Menu-->
            <div class="Dropdown">
              <div class="PortaLinks">
                <ul>
                  <li>
                    <a class="LinkMenu" href="/">{{
                      $t('public.headMenu.home')
                    }}</a>
                  </li>
                  <li>
                    <a
                      class="LinkMenu"
                      href="/tomador-de-medidas"
                      target="_self"
                      >{{ $t('public.headMenu.measurement') }}<br />{{
                        $t('public.headMenu.taker')
                      }}</a
                    >
                  </li>
                  <li>
                    <a class="LinkMenu" href="/consultor-optico" target="_self"
                      >{{ $t('public.headMenu.optical') }}<br />{{
                        $t('public.headMenu.consultant')
                      }}</a
                    >
                  </li>
                  <li>
                    <a class="LinkMenu" href="/tabela-de-lentes" target="_self"
                      >{{ $t('public.headMenu.lens') }}<br />{{
                        $t('public.headMenu.table')
                      }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="LinkMenu LinkGo BtnScrollMais200"
                      href="#Planos"
                      >{{ $t('public.headMenu.plans') }}</a
                    >
                  </li>
                  <li class="BtnOnlyDesktop LiMenor">
                    <a href="#FaleConosco" class="BtnScroll">
                      <img
                        src="./svg/Menu_FaleConosco.svg"
                        class="IconeEmail"
                        alt=""
                        title="FALE CONOSCO"
                      />
                    </a>
                  </li>
                  <div class="LiMenor"><Locale :isFromPublic="true" /></div>
                  <li class="LiMenor" v-if="user" style="cursor: pointer">
                    <div
                      link-class="d-flex align-items-center"
                      @click="$router.push('/admin')"
                    >
                      <feather-icon
                        size="16"
                        color="white"
                        icon="UserIcon"
                        class="mr-50"
                      />
                    </div>
                  </li>
                  <li class="LiMenor" v-if="user" style="cursor: pointer">
                    <div
                      link-class="d-flex align-items-center"
                      @click="logout()"
                    >
                      <feather-icon
                        size="16"
                        color="white"
                        icon="LogOutIcon"
                        class="mr-50"
                      />
                    </div>
                  </li>
                  <li class="LiMenor" v-if="!user">
                    <SignIn />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <div id="Home" class="OverlayMenu"></div>
    <div class="PorqueVerde_TabelaOnline d-none d-sm-flex">
      <a
        class="Pergunta"
        id="PorqueVerdeTabelaOnline"
        @click="PorqueVerdeButton()"
      >
        {{ $t('public.whyGreenButton.title') }}
      </a>
      <div id="Resposta" class="Resposta">
        <div id="TextoResposta" class="TextoResposta">
          <p class="SmallTxt">
            {{ $t('public.whyGreenButton.body1') }}<br />
            {{ $t('public.whyGreenButton.body2') }}
          </p>
        </div>
      </div>
    </div>

    <section class="HeaderInterno">
      <div class="container-fluid NoPadding">
        <img
          src="./images/TomadorMedidas_Header_Internas.png"
          class="img-fluid d-none d-sm-block"
          alt="Lensxpert"
        />

        <img
          src="./images/TomadorMedidas_Header_Internas_Mobile.png"
          class="img-fluid d-block d-sm-none"
          alt="Lensxpert"
        />
      </div>
    </section>

    <section class="SectionConteudoInternas" id="Apresentacao">
      <div class="container">
        <div class="col-12 col-md-8 offset-md-2">
          <h4 class="LabelBlue">{{ $t('') }}</h4>

          <h3 class="AzulClaro1 top10">
            {{ $t('public.tomadorMedidas.sectionSubtitle') }}
          </h3>
        </div>

        <div class="col-12 col-md-8 offset-md-2 top20 TextoResponsivo">
          <p class="Grey1 TextoConteudo">
            {{ $t('public.tomadorMedidas.more.sectionDescription11') }}
            <strong class="AzulClaro1">{{
              $t('public.tomadorMedidas.more.sectionDescription12')
            }}</strong>
          </p>

          <p v-html="$t('public.tomadorMedidas.more.sectionDescription13')"></p>

          <p class="Grey1 TextoConteudo top10">
            {{ $t('public.tomadorMedidas.sectionDescription1') }}
          </p>

          <p class="Grey1 TextoConteudo top10">
            {{ $t('public.tomadorMedidas.sectionDescription2') }}
          </p>

          <p class="Grey1 TextoConteudo top10">
            {{ $t('public.tomadorMedidas.sectionDescription3') }}
          </p>
        </div>

        <div class="col-12 col-md-8 offset-md-2 top60">
          <div class="row TipoMedidas">
            <img
              src="./svg/Loja.svg"
              class="IconesTipoMedidas"
              alt="Lensxpert"
            />

            <h4
              class="AzulClaro1"
              v-html="$t('public.tomadorMedidas.more.sectionFeatures1')"
            ></h4>
          </div>

          <div class="row">
            <p class="Grey1 TextoConteudo top20">
              {{ $t('public.tomadorMedidas.more.sectionDescription2') }}
            </p>
          </div>
        </div>

        <div class="col-12 NoPadding top60">
          <div class="row">
            <div class="col-12 col-md-4">
              <div class="CardVisio">
                <div class="VisioImagem">
                  <img
                    src="./images/TomadorMedidas_Feature1.png"
                    class="img-fluid"
                    alt="Lensxpert"
                  />
                </div>

                <div class="VisioConteudo">
                  <div class="BadgeVisio">1</div>

                  <p class="MediumTxt Grey1">
                    {{ $t('public.tomadorMedidas.more.sectionDescription3') }}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-4">
              <div class="CardVisio">
                <div class="VisioImagem">
                  <img
                    src="./images/TomadorMedidas_Feature2.png"
                    class="img-fluid"
                    alt="Lensxpert"
                  />
                </div>

                <div class="VisioConteudo">
                  <div class="BadgeVisio">2</div>

                  <p class="MediumTxt Grey1">
                    {{ $t('public.tomadorMedidas.more.sectionDescription4') }}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-4">
              <div class="CardVisio">
                <div class="VisioImagem">
                  <img
                    src="./images/TomadorMedidas_Feature3.png"
                    class="img-fluid"
                    alt="Lensxpert"
                  />
                </div>

                <div class="VisioConteudo">
                  <div class="BadgeVisio">3</div>

                  <p class="MediumTxt Grey1">
                    {{ $t('public.tomadorMedidas.more.sectionDescription5') }}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-4">
              <div class="CardVisio">
                <div class="VisioImagem">
                  <img
                    src="./images/TomadorMedidas_Feature4.png"
                    class="img-fluid"
                    alt="Lensxpert"
                  />
                </div>

                <div class="VisioConteudo">
                  <div class="BadgeVisio">4</div>

                  <p class="MediumTxt Grey1">
                    {{ $t('public.tomadorMedidas.more.sectionDescription6') }}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-4">
              <div class="CardVisio">
                <div class="VisioImagem">
                  <img
                    src="./images/TomadorMedidas_Feature5.png"
                    class="img-fluid"
                    alt="Lensxpert"
                  />
                </div>

                <div class="VisioConteudo">
                  <div class="BadgeVisio">5</div>

                  <p class="MediumTxt Grey1">
                    {{ $t('public.tomadorMedidas.more.sectionDescription7') }}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-4">
              <div class="CardVisio">
                <div class="VisioImagem">
                  <img
                    src="./images/TomadorMedidas_Feature6.png"
                    class="img-fluid"
                    alt="Lensxpert"
                  />
                </div>

                <div class="VisioConteudo">
                  <div class="BadgeVisio">6</div>

                  <p class="MediumTxt Grey1">
                    {{ $t('public.tomadorMedidas.more.sectionDescription8') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-8 offset-md-2 top20">
          <p class="MediumTxt Black">
            {{ $t('public.tomadorMedidas.more.sectionFeatures2') }}
          </p>
        </div>

        <div class="col-12 col-md-8 offset-md-2 top10">
          <div style="padding: 56.25% 0 0 0; position: relative">
            <iframe
              src="https://player.vimeo.com/video/442742557?app_id=122963"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              "
              title="Lensxpert / Medição Completa"
              data-ready="true"
            ></iframe>
          </div>
        </div>

        <div class="col-12 col-md-8 offset-md-2 top60">
          <div class="row TipoMedidas">
            <img
              src="./svg/Ecommerce2.svg"
              class="IconesTipoMedidas"
              alt="Lensxpert"
            />

            <h4
              class="AzulClaro1"
              v-html="$t('public.tomadorMedidas.more.sectionFeatures3')"
            ></h4>
          </div>
        </div>

        <div class="col-12 col-md-8 offset-md-2 top20 TextoResponsivo">
          <p
            class="Black top10"
            v-html="$t('public.tomadorMedidas.more.sectionDescription9')"
          ></p>

          <p class="Grey1 top10">
            {{ $t('public.tomadorMedidas.more.sectionDescription10') }}
          </p>
        </div>

        <div class="col-12 NoPadding top60">
          <div class="row">
            <div class="col-12 col-md-4">
              <div class="CardVisio">
                <div class="VisioImagem">
                  <img
                    src="./images/TomadorMedidas_Feature7.png"
                    class="img-fluid"
                    alt="Lensxpert"
                  />
                </div>

                <div class="VisioConteudo">
                  <div class="BadgeVisio">1</div>

                  <p class="MediumTxt Grey1">
                    {{ $t('public.tomadorMedidas.more.sectionDescription11') }}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-4">
              <div class="CardVisio">
                <div class="VisioImagem">
                  <img
                    src="./images/TomadorMedidas_Feature8.png"
                    class="img-fluid"
                    alt="Lensxpert"
                  />
                </div>

                <div class="VisioConteudo">
                  <div class="BadgeVisio">2</div>

                  <p class="MediumTxt Grey1">
                    {{ $t('public.tomadorMedidas.more.sectionDescription12') }}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-4">
              <div class="CardVisio">
                <div class="VisioImagem">
                  <img
                    src="./images/TomadorMedidas_Feature9.png"
                    class="img-fluid"
                    alt="Lensxpert"
                  />
                </div>

                <div class="VisioConteudo">
                  <div class="BadgeVisio">3</div>

                  <p class="MediumTxt Grey1">
                    {{ $t('public.tomadorMedidas.more.sectionDescription13') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-8 offset-md-2 top20">
          <p class="MediumTxt Black">
            {{ $t('public.tomadorMedidas.more.sectionFeatures4') }}
          </p>
        </div>

        <div class="col-12 col-md-8 offset-md-2 top10">
          <div style="padding: 56.25% 0 0 0; position: relative">
            <iframe
              src="https://player.vimeo.com/video/442742566?app_id=122963"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              "
              title="Lensxpert / Medição com Cartão"
              data-ready="true"
            ></iframe>
          </div>
        </div>

        <div class="col-12 text-center top100">
          <img src="./images/1x150pixels.png" alt="Lensxpert" />
        </div>

        <div class="col-12 col-md-10 offset-md-1" id="Vantagens">
          <div class="row Features">
            <div class="col-12 col-md-4 r_100">
              <img
                src="./svg/MultiAcessos.svg"
                class="IconeFeatures"
                alt="Lensxpert"
              />

              <p class="MediumTxt AzulClaro1">
                <strong v-html="$t('tabela.sectionFeature65')"></strong>
              </p>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription83')"
              ></p>
            </div>

            <div class="col-12 col-md-4">
              <img
                src="./svg/Ecommerce.svg"
                class="IconeFeatures"
                alt="Lensxpert"
              />

              <p class="MediumTxt AzulClaro1">
                <strong v-html="$t('tabela.sectionFeature66')"></strong>
              </p>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription84')"
              ></p>
            </div>

            <div class="col-12 col-md-4">
              <img
                src="./svg/Precisao.svg"
                class="IconeFeatures"
                alt="Lensxpert"
              />

              <p class="MediumTxt AzulClaro1">
                <strong v-html="$t('tabela.sectionFeature67')"></strong>
              </p>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription85')"
              ></p>
            </div>

            <div class="col-12 col-md-4">
              <img
                src="./svg/Armazenados.svg"
                class="IconeFeatures"
                alt="Lensxpert"
              />

              <p class="MediumTxt AzulClaro1">
                <strong v-html="$t('tabela.sectionFeature68')"></strong>
              </p>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription86')"
              ></p>
            </div>

            <div class="col-12 col-md-4">
              <img
                src="./svg/Impressao.svg"
                class="IconeFeatures"
                alt="Lensxpert"
              />

              <p class="MediumTxt AzulClaro1">
                <strong v-html="$t('tabela.sectionFeature69')"></strong>
              </p>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription89')"
              ></p>
            </div>

            <div class="col-12 col-md-4">
              <img
                src="./svg/Lentes.svg"
                class="IconeFeatures"
                alt="Lensxpert"
              />

              <p class="MediumTxt AzulClaro1">
                <strong v-html="$t('tabela.sectionFeature70')"></strong>
              </p>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription90')"
              ></p>
            </div>

            <div class="col-12 col-md-4">
              <img
                src="./svg/AtendimentoDiferenciado.svg"
                class="IconeFeatures"
                alt="Lensxpert"
              />

              <p class="MediumTxt AzulClaro1">
                <strong v-html="$t('tabela.sectionFeature71')"></strong>
              </p>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription91')"
              ></p>
            </div>

            <div class="col-12 col-md-4">
              <img
                src="./svg/FacilAcessar.svg"
                class="IconeFeatures"
                alt="Lensxpert"
              />

              <p class="MediumTxt AzulClaro1">
                <strong v-html="$t('tabela.sectionFeature72')"></strong>
              </p>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription92')"
              ></p>
            </div>

            <div class="col-12 col-md-4">
              <img
                src="./svg/SeuDominio.svg"
                class="IconeFeatures"
                alt="Lensxpert"
              />

              <p class="MediumTxt AzulClaro1">
                <strong v-html="$t('tabela.sectionFeature73')"></strong>
              </p>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription93')"
              ></p>
            </div>

            <div class="col-12 col-md-4">
              <img
                src="./svg/InvestimentoAcessivel.svg"
                class="IconeFeatures"
                alt="Lensxpert"
              />

              <p class="MediumTxt AzulClaro1">
                <strong v-html="$t('tabela.sectionFeature74')"></strong>
              </p>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription94')"
              ></p>
            </div>

            <div class="col-12 col-md-4">
              <img
                src="./svg/MaiorLucratividade.svg"
                class="IconeFeatures"
                alt="Lensxpert"
              />

              <p class="MediumTxt AzulClaro1">
                <strong v-html="$t('tabela.sectionFeature75')"></strong>
              </p>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription95')"
              ></p>
            </div>

            <div class="col-12 col-md-4">
              <img
                src="./svg/MultiPlataforma.svg"
                class="IconeFeatures"
                alt="Lensxpert"
              />

              <p class="MediumTxt AzulClaro1">
                <strong v-html="$t('tabela.sectionFeature76')"></strong>
              </p>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription96')"
              ></p>
            </div>

            <div class="col-12 col-md-4">
              <img
                src="./svg/CrossBrowser.svg"
                class="IconeFeatures"
                alt="Lensxpert"
              />

              <p class="MediumTxt AzulClaro1">
                <strong v-html="$t('tabela.sectionFeature77')"></strong>
              </p>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription97')"
              ></p>
            </div>

            <div class="col-12 col-md-4">
              <img
                src="./svg/Seguro.svg"
                class="IconeFeatures"
                alt="Lensxpert"
              />

              <p class="MediumTxt AzulClaro1">
                <strong v-html="$t('tabela.sectionFeature78')"></strong>
              </p>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription98')"
              ></p>
            </div>

            <div class="col-12 col-md-4">
              <img
                src="./svg/OnlineModerno.svg"
                class="IconeFeatures"
                alt="Lensxpert"
              />

              <p class="MediumTxt AzulClaro1">
                <strong v-html="$t('tabela.sectionFeature79')"></strong>
              </p>

              <p
                class="MediumTxt Black top10"
                v-html="$t('tabela.sectionDescription99')"
              ></p>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-8 offset-md-2 top80">
          <p
            class="Black SmallTxt"
            v-html="$t('tabela.sectionDescription100')"
          ></p>
        </div>
      </div>
    </section>

    <planes></planes>

    <section class="FaleConosco FaleConoscoHome" id="FaleConosco">
      <div class="container ContainerFaleConosco">
        <div class="col-12 col-md-8 offset-md-2 top100">
          <img
            src="./svg/FaleConosco.svg"
            class="IconeFaleConosco"
            alt="LensXpert"
          />

          <h5
            class="AzulClaro1"
            v-html="$t('public.home.sectionDescription1')"
          ></h5>

          <p
            class="MediumTxt Black top10"
            v-html="$t('public.home.sectionDescription2')"
          ></p>
        </div>
        <br /><br />

        <Contact />
      </div>
    </section>
    <footer>
      <div class="container">
        <div class="col-12">
          <div class="row HeightFooter">
            <img
              src="./svg/LensExpert___Logo.svg"
              class="Logo"
              alt="LensXpert"
            />
            <p
              class="SmallTxt White top20 text-center"
              v-html="$t('public.home.sectionDescription3')"
            ></p>
          </div>
        </div>
      </div>
    </footer>
    <a
      id="BackOnTop"
      href="#Home"
      class="BackOnTop d-flex align-items-center justify-content-center"
    >
      <img src="./svg/BackTop.svg" class="BackIcone" alt="LensXpert" />
    </a>
  </div>
</template>

<script>
import { PorqueVerdeButton } from './js/PorqueButton.js'
import { ScrollScript } from './js/ScrollScript.js'
import { OpenMenu, CloseMenu } from './js/ScrollMenuButton.js'

import Planes from '@/views/public/sections/Plans.vue'
import SignIn from '@/components/SignIn.vue'
import Contact from '@/components/Contact.vue'
import Locale from '@/@core/layouts/components/app-navbar/components/Locale.vue'

export default {
  components: {
    Planes,
    SignIn,
    Contact,
    Locale,
  },
  created() {
    window.addEventListener('scroll', ScrollScript)
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    },
  },
  methods: {
    PorqueVerdeButton,
    ScrollScript,
    OpenMenu,
    CloseMenu,
    async logout() {
      await this.$store.dispatch('auth/logout')
    },
  },
  methods: {
    PorqueVerdeButton,
    ScrollScript,
    OpenMenu,
    CloseMenu,
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import 'css/animate.css';
@import 'css/bootstrap.min.css';
@import 'css/cubeportfolio.min.css';
@import 'css/font-awesome.min.css';
@import 'css/jquery.fancybox.css';
@import 'css/MyColors.css';
@import 'css/MyForm.css';
@import 'css/MyNav.css';
@import 'css/MyResponsive.css';
@import 'css/MyStyle.css';
@import 'css/style.css';

html {
  scroll-behavior: smooth;
}

.plans-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  justify-content: center;
}

h2.plan-price {
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 600;
  font-size: 5rem;
  line-height: 55px;
  letter-spacing: 2px;
  margin: 0;
  padding: 0;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

h2.plan-price span {
  font-size: 2rem;
  line-height: 2rem;
}

h2.plan-price span.unit {
  vertical-align: super;
}

h2.plan-price span.month {
  font-weight: normal;
  color: #999;
}

.dark-layout h2.plan-price span.month {
  color: #aaa;
}

footer {
  width: 100%;
  height: 300px;
  background: #0c8cb9 url('../../views/public/images/Footer_HeaderHome.png') top
    center no-repeat;
  background-size: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

footer .HeightFooter {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

footer .Logo {
  width: auto;
  height: 85px;
  margin: 0 0 0 0;
}

footer .Assinatura {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  margin: 0 auto;
}

footer .SmallTxt {
  letter-spacing: 2px;
}
</style>
